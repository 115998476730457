<template>
  <div class="pageMain">
    <div class="">
      <!-- 组织项目树 -->

      <!-- <OriginTree class="tree" :filterText="filterText" @checked="init">
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            info="项目信息"
            :iconShow="false"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="filterText"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>
      </OriginTree> -->

      <!--右边列表  -->
      <div class="">
        <SearchHead @search="search" @reset="reset">
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="版本类别">
              <el-select
                size="small"
                clearable
                v-model="searchForm.type"
                placeholder="请选择版本类别"
              >
                <el-option
                  v-for="item in equipType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="版本信息">
              <el-input
                size="small"
                clearable
                v-model="searchForm.ver"
                placeholder="请输入版本信息"
              ></el-input>
            </el-form-item>
            <el-form-item label="项目名称">
              <el-input
                size="small"
                clearable
                v-model="searchForm.projName"
                placeholder="请输入项目名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="程序标识">
              <el-input
                size="small"
                clearable
                v-model="searchForm.built"
                placeholder="请输入程序标识"
              ></el-input>
            </el-form-item>
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
          :total="total"
          title="批量升级"
        >
          <template slot="btns">
            <el-button type="success" size="small" @click="addFrom" class="btn"
              >最新升级</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="addFrom2"
              class="btn2"
              >历史升级</el-button
            >
          </template>

          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column prop="built" label="程序标识" align="center">
          </el-table-column>
          <el-table-column prop="type" label="版本类别" align="center">
          </el-table-column>
          <el-table-column prop="ver" label="版本信息" align="center">
          </el-table-column>
          <el-table-column
            prop="duid"
            label="设备编号"
            show-overflow-tooltip
           
          ></el-table-column>
          <el-table-column prop="category" label="升级类型">
            <template slot-scope="scope">
              <div v-if="scope.row.category == 1" >历史更新</div>
              <div v-if="scope.row.category == 0">最新更新</div>
            </template>
          </el-table-column>
          <el-table-column prop="builtTime" label="添加时间"  show-overflow-tooltip></el-table-column>
          <el-table-column prop="state" label="升级状态">
            <template slot-scope="scope">
              <div v-if="scope.row.state == 1">已升级</div>
           
              <div v-if="scope.row.state == 0">未升级</div>
            </template>
          </el-table-column>
          <el-table-column prop="addtime" label="升级时间"></el-table-column>
          <!-- <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span @click="detailItem(scope.row)" class="detail">
                <i class="el-icon-edit"></i>
              </span>
              <span @click="deleteItem(scope.row)" class="detail delete">
                <i class="el-icon-delete"></i>
              </span>
            </template>
          </el-table-column> -->
        </CustomTables>
      </div>
    </div>
    <NewVersion
      :visible.sync="showDialog"
      @close="colseDialog"
      :title="title"
      :is-single="true"
      @done="done"
    ></NewVersion>
    <HistoryVersion
      :visible.sync="showDialog2"
      @close="colseDialog2"
      :title="title"
      :is-single="true"
      @done="historyDone"
    ></HistoryVersion>
  </div>
</template>

<script>
import OriginTree from "../../../components/common/OriginTree.vue";
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import NewVersion from "../../../components/systemManage/NewVersion.vue";
import HistoryVersion from "../../../components/systemManage/HistoryVersion.vue";
import sysManage from "../../../api/modules/sysManage";
import { equipType, deviceState } from "../../../config/dataStatus";
export default {
  components: {
    OriginTree,
    SearchHead,
    CustomTables,
    SearchLeft,
    NewVersion,
    HistoryVersion,
  },
  name: "statistics",
  data() {
    return {
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      filterText: "",
    
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      total: 0,
      show: true,
      showDialog: false,
      showDialog2: false,
      title: "",
      equipType: equipType,
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    addFrom() {
      this.showDialog = true;
      this.title = "最新版本";
    },
    addFrom2() {
      this.showDialog2 = true;
      this.title = "历史版本";
    },
   
    getList() {
      sysManage.getVerLogListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
        orgaId: this.orgaId,
      };
      this.getList();
    },
    tableExport() {
      // 导出
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    detailItem() {
      //详情
    },
    historyDone() {
      this.getList();
      this.colseDialog2 = false;
    },
    deleteItem() {},
    colseDialog() {
      this.showDialog = false;
    },
    done(){
      this.getList();
      this.showDialog = false;
    },
    colseDialog2() {
      this.showDialog2 = false;
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getList();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.currPage = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  height: 100%;

  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
}
.form {
  width: 78%;
  margin-left: 2%;
}
.formItem {
  margin-bottom: 0px;
}
.btn {
  background-color: #008f4d;
  // color: #008f4d;
}
.btn2 {
  // background-color:#3399ff;
}
.detail {
  color: #008f4d;
}
</style>
